@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-background;
}

a {
  @apply text-brand-orange;
}

/*h1 {
  @apply text-2xl;
  @apply text-brand-dark-blue;
}

h2 {
  @apply text-xl;
  @apply text-brand-dark-blue;
}

h3,
h4,
h5,
h6 {
  @apply text-lg;
  @apply text-brand-light-blue;
}*/

.content h1 {
  @applt mb-4;
  @apply mt-1;
  @apply text-2xl;
  @apply text-brand-dark-blue;
}

.content h2 {
  @apply mb-3;
  @apply mt-1;
  @apply text-xl;
  @apply text-brand-dark-blue;
}

.content h3,
.content h4,
.content h5,
.content h6 {
  @apply mb-2;
  @apply mt-1;
  @apply text-lg;
  @apply text-brand-light-blue;
}

.content ul {
  @apply list-disc;
  @apply ml-8;
}

.details-table {
  @apply w-full;
  @apply rounded-xl;
}

.details-table tr:nth-of-type(even) {
  @apply bg-gray-300;
}

.details-table td:first-of-type:not([colspan='2']) {
  @apply bg-brand-orange;
  @apply text-white;
}

.details-table td {
  @apply p-2;
}

.details-table th {
  @apply bg-brand-dark-blue;
  @apply text-white;
}

.details-table th:first-of-type {
  @apply bg-brand-orange;
}

.details-table td:first-of-type a {
  @apply text-white;
}

.ck-content {
  @apply prose;
  @apply max-w-none;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  @apply bg-brand-dark-blue;

  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
